<template>
  <div v-if="cabin" class="dashboard-booking">
    <router-link :to="{ name: 'hytte', params: { cabinId: cabin.id }}">
      <div class="dashboard-booking_content">
        <div class="dashboard-booking_content-image">
          <img :src="getCabinImage()" :alt="cabin.name">
        </div>
        <div class="dashboard-booking_content-information">
          <h3>{{ cabin.name }}</h3>
          <span class="dashboard-booking_content-information--dates">{{ formatDate(booking.fromDateUtc) }} - {{ formatDate(booking.toDateUtc) }}</span>
        </div>
      </div>
    </router-link>
    <div class="dashboard-booking_actions">
      <router-link :to="{ name: 'hytte', params: { cabinId: cabin.id }}">
        <Button color="grey" text="Endre booking"></Button>
      </router-link>
      <Button @click="deleteBooking()" color="grey" text="Slett booking"></Button>
    </div>
  </div>
</template>
<script>
import Helpers from '@/helpers/helpers';
import CabinService from '@/services/CabinService';
import Button from '@/components/Button';

export default {
  name: 'DashboardBooking',
  components: {
    Button
  },
  props: {
    booking: Object
  },
  data() {
    return {
      cabin: undefined
    }
  },
  methods: {
    formatDate: Helpers.formatDate,
    getCabinImage() {
      if(this.cabin.content.length > 0) {
        return this.cabin.content[0].storageUrl + this.cabin.storageTokenQuery;
      }
      return false;
    },
    deleteBooking() {
      this.$emit('deleteBooking', this.booking.id);
    }
  },
  async created() {
    const { data } = await CabinService.getCabin(
      this.booking.cabinId
    );

    this.cabin = data;
  }
}
</script>
<style lang="scss" scoped>
  .dashboard-booking {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F0F1F0;
    border-radius: 14px;
    padding: .5rem 1rem;
    @media (max-width: $breakpoint-mobile) {
      justify-content: flex-start;
    }
    &_content {
      display: flex;
      justify-content: flex-start;
      &-image {
        img {
          max-width: 120px;
          border-radius: 8px;
          margin: .5rem .5rem .5rem 0;
          margin-right: 1rem;
          max-height: 70px;
        }
      }
      &-information {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        @media (max-width: $breakpoint-mobile) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
        h3 {
          font-weight: 700;
          line-height: 29px;
          margin-bottom: 0;
          margin-right: 1rem;
          color: black;
        }
        &--dates {
          background-color: $warmth;
          border-radius: 8px;
          color: white;
          padding: .35rem;
        }
      }
    }

    &_actions {
      display: none;
      justify-content: flex-end;
      align-items: center;
      button:not(:last-child), a:not(:last-child) {
        margin-right: .5rem;
      }
      @media (min-width: $breakpoint-mobile) {
        display: flex;
      }
    }
  }
</style>
