<template>
  <div class="market-item">
    <div class="market-item_image">
      <img src="@/assets/images/hyttefeberMarked.png" alt="Hyttefeber">
    </div>
    <div class="market-item_content">
      <p class="subtitle">
        Sponset
      </p>
      <h3>Muskoka Pine Chair</h3>
      <p>Disse stolene er ingen klassiker uten grunn, og er utrolig behaglige å sitte i.
        Man sitter godt og avslappet, selv uten puter eller skinn i ryggen - noe som også kler disse stolene veldig godt.
      </p>
      <a href="https://hyttefeber.no/products/muskoka-pine-chair-fra-espegard-2580?_pos=2&_sid=b42005b03&_ss=r" class="market-item-buy">Kjøp <ArrowUpRight /></a>
    </div>
  </div>
</template>
<script>
import ArrowUpRight from '@/assets/arrowUpRight.svg';

export default {
  name: 'MarketItem',
  components: {
    ArrowUpRight
  }
}
</script>
<style lang="scss" scoped>
  .market-item {
    &_image {
      border-radius: 8px;
      margin: 0;
      padding: 0;
      img {
        max-width: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        margin: 0;
      }
    }
    &_content {
      margin-top: -5px;
      padding: 2rem 1.5rem 2rem 1.5rem;
      background-color: white;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      p {
        line-height: 1.3rem;
      }
      p.subtitle {
        text-transform: uppercase;
        color: $dark-grey;
        letter-spacing: .2rem;
      }
      h3 {
        font-weight: 500;
      }
      a.market-item-buy {
        display: block;
        max-width: 100%;
        text-align: center;
        font-size: 18px;
        padding: 1rem;
        border-radius: 8px;
        color: black;
        cursor: pointer;
        background-color: rgba(#1AA786, 0.33);
      }
      a.market-item-buy:hover {
        background-color: rgba(#1AA786, 0.5);
      }
    }
  }
</style>
