<template>
  <ConfirmationDialog ref="confirmationDialog"></ConfirmationDialog>
  <section class="dashboard">
    <div v-if="cabinsFiltered.length" class="dashboard_cabins">
      <div class="dashboard-headers">
        <h2>Tilgjengelige hytter</h2>
        <Button @click="goToCabins()" color="green" size="small" text="Se alle hytter"></Button>
      </div>
      <div class="dashboard-content">
        <row container :gutter="20">
          <column v-for="item in cabinsFiltered" :key="item.id" :xs="12" :md="6" :lg="6">
            <DashboardCabin :cabin="item"></DashboardCabin>
          </column>
        </row>
      </div>
    </div>
    <div v-else class="dashboard_cabins_none">
      <div class="dashboard_cabins_none-content">
        <div class="dashboard_cabins_none-content__inner">
          <h2>Kom i gang</h2>
          <p>Opprett din første hytte kjapt og enkelt,
            eller sjekk varslinger om du har blitt invitert til en hytte.
          </p>
          <Button @click="goToAddCabin()" color="pink" text="Opprett ny hytte" size="large" symbol="plus"></Button>
        </div>
      </div>
    </div>
    <div v-if="bookingsFiltered.length" class="dashboard_bookings">
      <div class="dashboard-headers">
        <h2>Neste booking</h2>
      </div>
      <div class="dashboard-content">
        <row container :gutter="20">
          <column v-for="item in bookingsFiltered" :key="item.id" :xs="12" :md="12" :lg="12">
            <DashboardBooking :booking="item" @deleteBooking="bookingDelete"></DashboardBooking>
          </column>
        </row>
      </div>
    </div>
    <div class="dashboard_market">
      <div class="dashboard-headers">
        <h2>Marked</h2>
      </div>
      <div class="dashboard-content">
        <row container :gutter="20">
          <column :xs="12" :md="4" :lg="4">
            <MarketItem></MarketItem>
          </column>
        </row>
      </div>
    </div>
  </section>
</template>
<script>
import CabinService from '@/services/CabinService';
import BookingService from '@/services/BookingService';
import store from '@/store';
import Button from '@/components/Button';
import DashboardBooking from '@/components/cabins/DashboardBooking';
import DashboardCabin from '@/components/cabins/DashboardCabin';
import MarketItem from '@/components/market/MarketItem';
import ConfirmationDialog from '@/components/modals/ConfirmationDialog';

export default {
  components: {
    Button,
    DashboardBooking,
    DashboardCabin,
    MarketItem,
    ConfirmationDialog
  },
  data() {
    return {
      cabins: [],
      bookings: []
    }
  },
  computed: {
    cabinsFiltered() {
      return this.cabins.slice(0, 2);
    },
    bookingsFiltered() {
      return this.bookings.slice(0, 2);
    }
  },
  methods: {
    goToCabins() {
      this.$router.push({ name: 'Cabins' });
    },
    goToAddCabin() {
      this.$router.push({ name: 'AddCabin' });
    },
    async bookingDelete(val) {
      const confirmDelete = await this.$refs.confirmationDialog.show({
        title: 'Slett booking',
        message: 'Er du sikker på du vil slette denne bookingen?',
        okButton: 'Slett booking',
      })
      if (confirmDelete) {
        await BookingService.deleteBooking(val);
        const { data } = await BookingService.getActiveBookings();
        this.bookings = data;
        this.$toast.success(`Booking ble slettet`);
      } else {
        this.$toast.info(`Sletting avbrutt`);
      }
    }
  },
  async beforeRouteEnter(to, from, next) {
    const cabins = await CabinService.getCabins(
      store.getters['users/userId']
    );
    const bookings = await BookingService.getActiveBookings();
    next(vm => {
      vm.cabins = cabins.data;
      vm.bookings = bookings.data;
    });
  }
}
</script>
<style lang="scss" scoped>
  .dashboard {
    &-headers {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .5rem;
      h2 {
        font-weight: 500;
        line-height: 24px;
        padding-top: 1rem;
      }
    }
    &-content {
      margin-bottom: 2rem;
    }
  }
  .dashboard_cabins_none {
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
    height: 300px;
    background-image: url('../assets/hans-isaacson-unsplash1.png');
    background-size: cover;
    background-position: center center;
    border-radius: 8px;
    &-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: linear-gradient(266.04deg, #000000 -6.08%, rgba(0, 0, 0, 0.64) 64.96%, rgba(0, 0, 0, 0) 96.76%);
      border-radius: 8px;
      &__inner {
        margin-top: 5rem;
        width: 90%;
        @media (min-width: $breakpoint-mobile) {
          width: 30%;
        }
        padding: 2rem;
        h2 {
          color: white;
          font-weight: 500;
          font-size: 28px;
        }
        p {
          color: white;
          font-size: 16px;
          line-height: 22.4px;
        }
      }
    }
  }
</style>
