<template>
  <router-link :to="{ name: 'hytte', params: { cabinId: cabin.id }}">
    <div class="dashboard-cabin">
      <img :src="getCabinImage()" :alt="cabin.name">
      <h3>{{ cabin.name }}</h3>
    </div>
  </router-link>
</template>
<script>
import Helpers from '@/helpers/helpers';

export default {
  name: 'DashboardCabin',
  props: {
    cabin: Object
  },
  methods: {
    createUrlForCustomSize: Helpers.createUrlForCustomSize,
    getCabinImage() {
      if(this.cabin.content.length > 0) {
        return `${this.createUrlForCustomSize(this.cabin.content[0].storageUrl, 'small')}${this.cabin.storageTokenQuery}`;
      }
      return false;
    }
  }
}
</script>
<style lang="scss" scoped>
  .dashboard-cabin {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #F0F1F0;
    border-radius: 14px;
    padding: .5rem 1rem;
    img {
      max-width: 120px;
      border-radius: 8px;
      margin: .5rem 1rem .5rem 0;
      max-height: 70px;
    }
    h3 {
      color: black;
      font-weight: 700;
      line-height: 29px;
      margin-bottom: 0;
    }
  }
</style>
